import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ROUTE_CONFIG } from '../../constants/routeConfig.constant'

interface Tab {
  label: string
  route: string
}

const getUserPermissions = () => {
  const user = JSON.parse(localStorage.getItem('user')!)
  return user && Array.isArray(user.permission) ? user.permission : []
}

const permissions = getUserPermissions()

const tabs: Tab[] = [
  ...(permissions.includes('learners')
    ? [
        {
          label: 'Learners',
          route: ROUTE_CONFIG.STUDENT_LIST.path,
        },
      ]
    : []),

  { label: 'My Program', route: ROUTE_CONFIG.HOME.path },
  ...(permissions.includes('view_analytics')
    ? [
        {
          label: 'Analytics',
          route: ROUTE_CONFIG.ANALYTICS.path,
        },
      ]
    : []),
  ...(permissions.includes('upload_reports')
    ? [{ label: 'Upload Report', route: ROUTE_CONFIG.UPLOAD_REPORT.path }]
    : []),
  { label: 'Assessment', route: ROUTE_CONFIG.ASSESSMENT.path },
]


const NavigationTabs = ({ isMobile }: any) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [activeTab, setActiveTab] = useState('')

  useEffect(() => {
    const currentRoute = location.pathname
    setActiveTab(currentRoute)
  }, [location.pathname])

  const handleTabClick = (route: string) => {
    navigate(route)
  }

  const btnGroupClasses = classNames('flex', {
    'flex-grow justify-center flex flex-row space-x-8 desktop-menu': !isMobile,
    'md:hidden mobile-menu flex flex-col': isMobile,
  })

  return (
    <div className={btnGroupClasses}>
      {tabs.map(tab => (
        <div key={tab.route}>
          <Link
            to={tab.route}
            className={classNames(
              'px-3 py-2 rounded-full cursor-pointer transition-colors duration-300',
              {
                'bg-[#202e59] text-white text-bold': activeTab === tab.route,
                'text-white hover:text-gray-200': activeTab !== tab.route,
              }
            )}
            onClick={() => handleTabClick(tab.route)}>
            {tab.label}
          </Link>
        </div>
      ))}
    </div>
  )
}

export default NavigationTabs
