import React, { useState } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AccessProtected } from './ProtectedRoute'
import Login from './pages/Login/Login'
import SuperAdminLogin from './pages/Login/super_admin_login'
import Layout from './pages/Dashboard/Layout'
import AdminDashboard from './pages/Dashboard/AdminDashboard'
import Learner_list from './pages/Learners/Learner_list'
import ManageLearner from './pages/Learners/ManageLearner'
import Learners from './pages/Learners/Learners'
import NotFound from './pages/NotFound'
import Settings from './pages/Settings'

import Program from './pages/Program'
import CreateProgram from './pages/Program/CreateProgram'
import CreateModule from './pages/Program/CreateModule'
import AssignProgram from './pages/Program/AssignProgram'

import AddUser from './pages/Settings/AddUser'
import EditUserAdmin from './pages/Settings/EditUserAdmin'
import UserSettings from './pages/Settings/UserSettings'
import ManageUsers from './pages/Settings/ManageUsers'
import Edit from './pages/Settings/Edit'
import Users from './pages/Settings/Users'
import ProfileDetails from './pages/Settings/ProfileDetails'

import ManageBU from './pages/Settings/ManageBU'
import UserSettingsBU from './pages/Settings/UserSettingsBU'
import AddUsersBU from './pages/Settings/AddUsersBU'
import EditUserBU from './pages/Settings/EditUserBU'

import AnalyticsIndex from './pages/Analytics/index'
import UploadReport from './features/UploadReport'
import EditProfile from './features/EditLearners'
import UserForm from './pages/Settings/Users'
import AddAssessment from './components/AddAssessment'
import DepartmentLevel from './pages/Analytics/reports/Department'
import OverAllLevel from './pages/Analytics/reports/Overall'
import AssessmentLevel from './pages/Analytics/reports/AssessmentCharts/AssessmentLevel'
import LearnerLevel from './pages/Analytics/reports/Learners/Learner'

import MasterAdmin from './pages/Login/master_admin_login'
import WhiteLabeling from './pages/Functionality/WhiteLabeling'
import CreateSadmin from './pages/Functionality/SuperAdmin/CreateSadmin'
import MyCalendar from './pages/Functionality/calendar'
import Logo from '../src/pages/Functionality/Logo'
import AdminListPage from '../src/pages/Functionality/SuperAdmin/AdminTable'
interface AppRoutesProps {
  isLoggedIn: boolean
  setIsLoggedIn: (isLoggedIn: boolean) => void
}

const AppRoutes = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const handleLogin = () => {
    setIsLoggedIn(true)
  }

  const handleLogout = () => {
    setIsLoggedIn(false)
  }
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/super_admin" element={<SuperAdminLogin />} />
      <Route path="/master_admin" element={<MasterAdmin />} />
      <Route path="/Add_Super_Admin" element={<CreateSadmin />} />
      <Route path="/admin-list" element={<AdminListPage />} />

      <Route path="/" element={<Layout />}>
        <Route index path="/dashboard" element={<AdminDashboard />} />
        <Route path="/super_admin" element={<SuperAdminLogin />} />
        <Route path="/login" element={<Login />} />
        <Route
          element={
            <AccessProtected
              requires={['assign_learners', 'unassign_learners']}
            />
          }>
          <Route path="/learners" element={<Learners />}>
            <Route index element={<Learner_list />} />
            <Route path="learners-list" element={<Learner_list />} />
            <Route path="manageLearners" element={<ManageLearner />} />
            <Route path="editLearner" element={<EditProfile />} />
          </Route>
        </Route>
        <Route
          element={
            <AccessProtected
              requires={[
                'view_program',
                'create_program',
                'edit_program',
                'delete_program',
                'modify_program_settings',
              ]}
            />
          }>
          <Route path="/program" element={<Program />}>
            <Route path="create" element={<CreateProgram />} />
            <Route path=":programId/createModule" element={<CreateModule />} />
            <Route path=":programId/:moduleId" element={<CreateModule />} />
            <Route path=":programId/details" element={<CreateProgram />} />
            <Route path=":programId/assign" element={<AssignProgram />} />
            <Route path=":programId/analytics" element={<NotFound />} />
            <Route index element={<NotFound />} />
          </Route>
        </Route>
        <Route path="/settings" element={<Settings />}>
          <Route path="profile" element={<ProfileDetails />} />
          <Route
            element={
              <AccessProtected
                requires={['add_users', 'manage_users', 'remove_users']}
              />
            }>
            <Route path="manageUsers" element={<UserSettings />}>
              <Route index element={<ManageUsers />} />
              <Route path="addUsers" element={<AddUser />}>
                <Route index element={<Users />} />
                <Route path="Edit" element={<Edit />} />
              </Route>
              <Route path="editAdmin" element={<EditUserAdmin />} />
            </Route>
          </Route>
          <Route path="editBu" element={<EditUserBU />} />
          <Route path="icons" element={<Logo />} />
          <Route
            element={<AccessProtected requires={['add_bu', 'manage_bu']} />}>
            <Route path="manageBU" element={<UserSettingsBU />}>
              <Route index element={<ManageBU />} />
              <Route path="addBU" element={<AddUsersBU />} />
              <Route path="editBU" element={<EditUserBU />} />
              <Route path="editAdmin" element={<UserForm />} />
            </Route>
          </Route>
        </Route>
        <Route element={<AccessProtected requires={['view_analytics']} />}>
          <Route path="/analytics" element={<AnalyticsIndex />}>
            <Route index path="overall" element={<OverAllLevel />} />
            <Route path="departmentLevel" element={<DepartmentLevel />} />
            <Route path="assessmentLevel" element={<AssessmentLevel />} />
            <Route path="learnerlevel" element={<LearnerLevel />} />
          </Route>
        </Route>
        <Route path="/upload-report" element={<UploadReport />} />
        <Route path="/addassessmentId" element={<AddAssessment />} />
        <Route path="/request_whitelabeling" element={<WhiteLabeling />} />
        <Route path="/calendar" element={<MyCalendar />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  )
}

export default AppRoutes
