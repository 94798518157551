import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import axios from 'axios'
import { ANALYTICS_API_URL } from '../../services'
import { IReportDropdownOptions } from './report.interface'
import ChildNav from '../../components/childNav'
import { getSuperAdmin, removeEmptyValues } from './utils'

const getUserPermissions = () => {
  const user = JSON.parse(localStorage.getItem('user')!)
  // Ensure permissions is always an array
  return user && Array.isArray(user.permission) ? user.permission : []
}

const permissions = getUserPermissions()

const childNavData = [
  ...(permissions.includes('view_progress_analytics')
    ? [{ path: `/analytics/overall`, linkText: 'Overall Progress' }]
    : []),
  ...(permissions.includes('view_department_level_analytics')
    ? [{ path: `/analytics/departmentLevel`, linkText: 'Department Level' }]
    : []),
  ...(permissions.includes('view_assessment_level_analytics')
    ? [{ path: '/analytics/assessmentLevel', linkText: 'Assessment Level' }]
    : []),
  ...(permissions.includes('view_learner_level_analytics')
    ? [{ path: '/analytics/learnerLevel', linkText: 'Learner Level' }]
    : []),
]

// const childNavData = [
//   { path: `/analytics/overall`, linkText: 'Overall Progress' },
//   { path: `/analytics/departmentLevel`, linkText: 'Department Level' },
//   { path: '/analytics/assessmentLevel', linkText: 'Assessment Level' },
//   { path: '/analytics/learnerlevel', linkText: 'Learner Level' },
// ]

const AnalyticsIndex = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [dropdownOptions, setDropdownOptions] = useState<
    IReportDropdownOptions
  >({
    assessments: [],
    batches: [],
    departments: [],
    organizations: [],
    programs: [],
    sections: [],
  })

  useEffect(() => {
    fetchFilterOptions()
  }, [])

  const fetchFilterOptions = async () => {
    try {
      const query = new URLSearchParams(removeEmptyValues({}))
      console.log(query)
      const response = await axios.get(
        `${ANALYTICS_API_URL}/super_admin_filter_options/?${query}`
      )
      if (!response.data.error) {
        setDropdownOptions(response.data)
      }
    } catch (error) {
      console.error('Error fetching filter options:', error)
    }
  }

  return (
    <div className="flex flex-row  justify-start">
      <div className="">
        <ChildNav childNavData={childNavData} />
      </div>
      <Outlet context={{ dropdownOptions }} />
    </div>
  )
}

export default AnalyticsIndex
