import React, {
  useMemo,
  useState,
  HTMLAttributes,
  HTMLProps,
  useReducer,
  useEffect,
} from 'react'
import { LearnerResponse } from 'src/interfaces/learner'
import { faker } from '@faker-js/faker'
import { useParams } from 'react-router-dom'
import { API_URL, fetchProgramData } from 'src/services'
import { Link } from 'react-router-dom'
import axios from 'axios'

const user = JSON.parse(localStorage.getItem('user') || '{}')

// Check if the user has the 'access' permission
const userPermissions = user?.permission || []
const accessButton = userPermissions.includes('button_access')

const range = (len: number) => {
  const arr: number[] = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}

import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  RowData,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  Table,
} from '@tanstack/react-table'
import { OrganizationOption } from 'src/interfaces/learner'
// import { makeData, Person } from './makeData'

interface IRenderTableProps {
  data: LearnerResponse[]
  updateParent: () => void
  unassign: () => void
  sort: () => void
  opcode: boolean
  type: string
  managelearners: (params: any) => void
  parentSelec: (params: any) => void
  parentSubmit: (value: any) => void
  organization: OrganizationOption[]
}
declare module '@tanstack/react-table' {
  //allows us to define custom properties for our columns
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select' | 'select-organization'
  }
}

const RenderTable = ({
  data,
  opcode,
  updateParent,
  sort,
  unassign,
  type,
  managelearners,
  parentSelec,
  parentSubmit,
  organization,
}: IRenderTableProps) => {
  //console.log(data)
  let url = location.href
  let { programId } = useParams()
  const [rowSelection, setRowSelection] = React.useState({})
  const [, setEnrolled] = React.useState([])
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )
  const [state, setState] = useState<number>(0)
  const [initData, setData] = React.useState<LearnerResponse[]>(data)
  const handleDelete = async (learnerId: string) => {
    if (!window.confirm('Are you sure you want to delete this learner?')) {
      return
    }
    try {
      const token = localStorage.getItem('token')
      const headers = {
        Authorization: 'Bearer ' + token,
      }
      const response = await axios.post(
        `${API_URL}/remove_learners/${learnerId}`,
        {
          headers,
        }
      )
      if (response.status === 200) {
        const updatedData = data.filter(learner => learner._id !== learnerId)
        setData(updatedData)

        console.log('Learner deleted successfully')
      } else {
        console.error('Failed to delete learner')
      }
    } catch (error) {
      console.error('Error deleting learner:', error)
    }
  }

  const userPermissions = user?.permission || []
  const accessButton = userPermissions.includes('Manage_access')

  useEffect(() => {
    // refreshData()
    let enrolled: any = []
    console.log(rowSelection)
    data.map((value: any) => {
      value.programs_assigned.map((next: any) => {
        if (next.program_id == programId) {
          // defaultSelection[index]=true
          enrolled.push(value)
        }
      })
    })
    if (!opcode) {
      // setRowSelection(defaultSelection)
    }
    setEnrolled(enrolled)

    console.log(rowSelection)
    setState(n => n + 1)
  }, [data])

  const columns = React.useMemo<ColumnDef<LearnerResponse, any>[]>(
    () => [
      {
        id: 'select',
        header: ({ table }) => (
          <IndeterminateCheckbox
            {...{
              checked: table.getIsAllRowsSelected(),
              indeterminate: table.getIsSomeRowsSelected(),
              onChange: table.getToggleAllRowsSelectedHandler(),
            }}
          />
        ),
        cell: ({ row }) => (
          <div className="px-1">
            <IndeterminateCheckbox
              {...{
                checked: row.getIsSelected(),
                disabled: !row.getCanSelect(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        ),
      },
      {
        accessorKey: 'learner_name',
        header: 'Learner Name',
        // cell: info => info.getValue(),
      },
      // {
      //   accessorKey: 'date_of_birth',
      //   header: 'DOB',
      //   cell: info => info.getValue(),
      // },
      {
        accessorFn: row => `${row.organization_id}`,
        id: 'organization_id',
        header: 'Organization Name',
        cell: info => {
          const org = organization.find(item => info.getValue() === item._id)
          return org?.organization_name ?? '-'
        },
        meta: {
          filterVariant: 'select-organization',
        },
      },
      {
        accessorKey: 'college_reg_no',
        header: 'College Reg No',
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'email_id',
        header: () => <span>Email ID</span>,
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'passed_out',
        header: 'Pass Out Year',
        cell: info => info.getValue(),
        meta: {
          filterVariant: 'select',
        },
      },
      {
        accessorKey: 'department',
        header: 'Department',
        meta: {
          filterVariant: 'select',
        },
      },

      {
        accessorKey: '_id',
        header: 'Action',
        cell: info => {
          return (
            accessButton && (
              <div className="flex gap-2">
                <Link
                  className="bg-[#202e59] text-white p-2 rounded-md"
                  to={`/learners/editLearner?learnerid=${info.getValue()}`}>
                  Edit
                </Link>
                <button
                  className="bg-red-600 text-white p-2 rounded-md"
                  onClick={() => handleDelete(info.getValue())}>
                  Delete
                </button>
              </div>
            )
          )
        },
      },
    ],
    [data, organization]
  )
  const uniqueDepartments = useMemo(
    () => [...new Set(data.map(item => item.department))],
    [data]
  )
  const uniquePassedOutYear = useMemo(
    () => [...new Set(data.map(item => item.passed_out))],
    [data]
  )
  const uniqueOrganization = useMemo(
    () => [...new Set(organization.map(item => item.organization_name))],
    [organization]
  )
  url.includes('manageLearners') == false ? columns.pop() : ''

  const table = useReactTable({
    data,
    columns,
    filterFns: {},
    state: {
      columnFilters,
      rowSelection,
    },
    enableRowSelection: true,

    onRowSelectionChange: value => {
      setRowSelection(value)
      console.log(rowSelection)
      parentSelec(value)
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(), //client side filtering
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
    enableMultiRowSelection: true,
  })

  const sendNotification = async (learnerArray: any[], programId: any) => {
    const programData = await fetchProgramData(programId)
    console.log('Fetched Program Data:', programData)

    const currentUserName = localStorage.getItem('user')
    let createdBy = null
    if (currentUserName) {
      try {
        const parsedUserName = JSON.parse(currentUserName)
        createdBy = parsedUserName.admin_name
      } catch (error) {
        console.error('Error parsing current user:', error)
      }
    }

    let { program_name, program_short_description } = programData
    const data = {
      name: program_name,
      description: program_short_description,
      createdBy: createdBy,
      learners: learnerArray,
      programId: programId,
      deadlineDate: new Date().toISOString(), // Example deadlineDate
    }

    const telegramData = {
      name: program_name,
      description: program_short_description,
      createdBy: createdBy,
      learners: learnerArray,
      type: 'program',
    }

    console.log('Notification Data to be Sent:', data)

    const token = localStorage.getItem('token')
    const headers = {
      Authorization: 'Bearer ' + token,
    }

    try {
      const response = await axios.post(`${API_URL}/send-notification`, data, {
        headers,
      })
      console.log('Response from API:', response)
    } catch (error) {
      console.error('Error sending notification:', error)
    }

    try {
      const response = await axios.post(
        `${API_URL}/telegram-program-notification`,
        { ...telegramData },
        { headers }
      )
      console.log('Response from API:', response)
    } catch (error) {
      console.error('Error sending notification:', error)
    }
  }

  const handleProgramSubmit = async (path: any) => {
    try {
      const learnerArray = data
        .filter((value: any, index: number) => {
          let keys = Object.keys(rowSelection)
          return keys.includes(index.toString())
        })
        .map((value: any) => value._id)

      console.log('Learner Array:', learnerArray)
      console.log('Program ID:', programId)

      const response = await fetch(`${API_URL}/${path}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: JSON.stringify({
          learnerArray,
          program_id: programId,
        }),
      })

      if (!response.ok) {
        const errorText = await response.text()
        console.error('Error Response:', errorText)
        return
      }

      // Fetch program details
      const programData = await fetchProgramData(programId)
      console.log('Fetched Program Data:', programData)

      const { program_name, program_start_date, program_end_date } = programData

      const calendarData = {
        title: program_name,
        startDate: program_start_date,
        endDate: program_end_date,
        color: 'red',
        isProgram: true,
        learners: learnerArray,
      }

      console.log('Calendar Data to be Sent:', calendarData)

      const token = localStorage.getItem('token')
      const headers = {
        Authorization: 'Bearer ' + token,
      }

      try {
        const calendarResponse = await axios.post(
          `${API_URL}/postCalendarData`,
          calendarData,
          { headers }
        )
        console.log('Response from API:', calendarResponse.data)
      } catch (error) {
        console.error('Error sending calendar data:', error)
      }

      // Send notification with learnerArray and programId only when assigning
      if (path === 'learnerprogramsAssign') {
        await sendNotification(learnerArray, programId)
      }

      const result = await response.json()
      console.log('Response Result:', result)

      setRowSelection({})
      updateParent()
      if (path === 'learnerprogramsAssign') {
        unassign()
      } else {
        sort()
      }
    } catch (error) {
      console.error('Fetch Error:', error)
    }
  }

  return (
    <div
      className="p-10 overflow-x-auto rounded-md shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]  bg-white"
      style={{ width: '70vw' }}>
      <b>No of Items Selected : {Object.keys(rowSelection).length}</b>
      <div>
        {opcode && (
          <div>
            No of Students Enrolled :
            {table.getPrePaginationRowModel().rows.length}
          </div>
        )}
      </div>
      <table className=" bg-gray-300 border-solid	 border-2	 border-gray-300">
        <thead className="border-solid border-2 border-gray-300">
          {table.getHeaderGroups().map(headerGroup => (
            <tr
              className="bg-gray-500  rounded-md py-2 text-lg"
              key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <>
                        <div
                          className={
                            header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : ''
                          }
                          onClick={header.column.getToggleSortingHandler()}>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                        {header.column.getCanFilter() ? (
                          <div>
                            <Filter
                              column={header.column}
                              uniqueDepartments={uniqueDepartments}
                              uniquePassedOutYear={uniquePassedOutYear}
                              uniqueOrganization={uniqueOrganization}
                              organization={organization}
                            />
                          </div>
                        ) : null}
                      </>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className="border-solid border-2 bg-white border-gray-100">
          {table.getRowModel().rows.map((row, i) => {
            return (
              <tr
                className={`text-center ${
                  i % 2 === 0 ? 'bg-gray-200' : 'bg-white'
                }`}
                key={row.id}>
                {row.getVisibleCells().map(cell => {
                  return (
                    <td
                      className="p-3 border-solid	 border-2	 border-gray-300"
                      key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}>
          {'<<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}>
          {'<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}>
          {'>'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}>
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}>
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

      {!opcode && !url.includes('manageLearners') && accessButton && (
        <button
          className="bg-green-600 text-white px-4 py-3 mx-2 rounded-lg"
          onClick={() => handleProgramSubmit('learnerprogramsAssign')}>
          Assign
        </button>
      )}

      {/* Render Unassign button if 'opcode' is truthy and user has access permission */}
      {opcode && accessButton && (
        <button
          onClick={() =>
            confirm('Are you sure you want to Unassign?')
              ? handleProgramSubmit('learnerprogramsUnassign')
              : null
          }
          className="bg-blue-800 text-white px-5 py-2 mx-2 rounded-lg">
          Unassign
        </button>
      )}

      <div>{table.getPrePaginationRowModel().rows.length} Rows</div>
    </div>
  )
}

function Filter({
  column,
  uniqueDepartments,
  uniquePassedOutYear,
  uniqueOrganization,
  organization,
}: {
  column: Column<any, any>
  uniqueDepartments: string[]
  uniquePassedOutYear: number[]
  uniqueOrganization: string[]
  organization: OrganizationOption[]
}) {
  const columnFilterValue = (column.getFilterValue() ?? '') as string
  const meta: any = column.columnDef.meta

  if (meta?.filterVariant === 'select') {
    return (
      <select
        value={columnFilterValue}
        onChange={e => column.setFilterValue(e.target.value)}
        className="mt-2 w-36">
        <option value="">All</option>
        {column.id === 'passed_out'
          ? uniquePassedOutYear.map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))
          : uniqueDepartments.map(value => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
      </select>
    )
  }
  if (meta?.filterVariant === 'select-organization') {
    return (
      <select
        value={columnFilterValue}
        onChange={e => column.setFilterValue(e.target.value)}
        className="mt-2 w-36">
        <option value="">All</option>
        {uniqueOrganization.map(name => {
          const org = organization.find(item => item.organization_name === name)
          return (
            <option key={org?._id} value={org?._id}>
              {name}
            </option>
          )
        })}
      </select>
    )
  }

  return (
    <input
      type="text"
      value={columnFilterValue}
      onChange={e => column.setFilterValue(e.target.value)}
      placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
      className="mt-2 w-36"
    />
  )
}

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate, rest.checked])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  )
}

export default RenderTable
