import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { API_URL } from '../../services/index'
import RenderTable from './Tablesection/TableDisplay'
import { Organization } from './Tablesection/TableDisplay'
import { toast } from 'react-toastify'
interface UserData {
  admin_name: string
  address: string
  city: string
  company_logo: string
  company_name: string
  country: string
  created_by: string
  email_id: string
  linkedin_url: string
  mobile_no: string
  permission: string[]
  profile_pic: string
  role: string
  social_profile: any[]
  state: string
  status: number
  username: string
  valid_from: string
  valid_to: string
  _id: string
}

const Manageusers = () => {
  const [adminUser, setAdminUser] = useState<Organization[]>([])
  const token = localStorage.getItem('token')
  const userDataString = localStorage.getItem('user')
  const userData: UserData = userDataString ? JSON.parse(userDataString) : null

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios(`${API_URL}/sub_admin/FetchAll`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + token,
          },
        })
        const data = response.data
        console.log(data.data.length)
        if (data && data.data.length !== 0) {
          setAdminUser(data.data)
        } else {
          setAdminUser([])
        }
      } catch (error) {
        toast.error('Error fetching AdminUser');
        console.error('Error fetching AdminUser:', error)
      }
    }

    fetchData()
  }, [])
  return (
    <div>
      <div className="flex">
        {userData.permission.includes('add_users') && (
          <div className="w-32 text-center text-lg my-3 right-0 text-white bg-green-700 px-2 py-3 rounded-md">
            <Link to="/settings/manageUsers/addUsers">
              <button>Add Users</button>
            </Link>
          </div>
        )}
      </div>
      <div>
        <RenderTable data={adminUser} isuser={true}></RenderTable>
      </div>
    </div>
  )
}

export default Manageusers
