import React, { useState, useEffect, useCallback, useRef } from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import NavigationTabs from './NavigationTabs'
import useData from '../../hooks/useData'
import { PermissionCheck } from 'src/services'
import ImageFetcher from '../../pages/Functionality/ImageFetcher'

interface UserData {
  super_admin: string
  admin_name: string
  address: string
  city: string
  company_logo: string
  company_name: string
  country: string
  created_by: string
  email_id: string
  linkedin_url: string
  mobile_no: string
  permission: string[] // Assuming permission is an array of strings
  profile_pic: string
  role: string
  social_profile: any[] // You can define a specific type if needed
  state: string
  status: number
  username: string
  valid_from: string
  valid_to: string
  _id: string
}

const Dropdown: React.FC = () => {
  const { user, removeUser } = useData()

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement>(null)

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropdownOpen(false)
    }
  }

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownOpen])

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  // Retrieve user data from local storage
  const adminName = user?.admin_name || 'Username'
  const userRole = user?.role || ''
  const userPermissions = user?.permission || []

  // Check if "View_profile" permission exists
  const canViewProfile = userPermissions.includes('View_profile')

  return (
    <div className="relative z-10" ref={dropdownRef}>
      <button
        className="flex items-center gap-3 rounded-full"
        onClick={toggleDropdown}>
        <span className="text-white hidden md:block">{adminName}</span>
      </button>
      {dropdownOpen && (
        <div className="absolute right-[-3.4rem] mt-4 w-48 bg-green-200 rounded-md shadow-sm py-3 text-gray-700 p-2">
          {canViewProfile && (
            <Link
              to="/settings/profile"
              className="block px-4 rounded-md py-2 text-center hover:bg-gray-100 p-10">
              Global Settings
            </Link>
          )}
          {userRole === 'super_admin' && (
            <Link
              to="/request_whitelabeling"
              className="block px-4 rounded-md py-2 text-center hover:bg-gray-100 p-10">
              Request WhiteLabeling
            </Link>
          )}
          <button
            onClick={removeUser}
            className="block px-4 py-2 rounded-md w-full hover:bg-gray-100">
            Logout
          </button>
        </div>
      )}
    </div>
  )
}

const Navbar: React.FC = () => {
  const { removeUser, token } = useData()
  const navigate = useNavigate()
  const location = useLocation()
  const { width } = useWindowSize()

  const [isMobile, setIsMobile] = useState(false)
  const [isMobileNavClicked, setIsMobileNavClicked] = useState(false)

  const fetchUserDataHandler = useCallback(async () => {
    const tokenValue = localStorage.getItem('token')
    if (tokenValue) {
      const expiryDate = localStorage.getItem('expiryDate')
      if (expiryDate && new Date(expiryDate) <= new Date()) {
        removeUser()
        navigate('/login')
      }
      if (!PermissionCheck(location.pathname)) {
        navigate('/dashboard') // Redirect to dashboard if permission check fails
      }
    } else {
      navigate('/login') // Redirect to login if token is not present
    }
  }, [token, navigate, removeUser, location.pathname])

  useEffect(() => {
    void fetchUserDataHandler()
  }, [fetchUserDataHandler, location.pathname])

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(width <= 768) // Adjust breakpoint as needed
      setIsMobileNavClicked(false) // Adjust breakpoint as needed
    }

    window.addEventListener('resize', handleResize)

    handleResize() // Call on initial render

    return () => window.removeEventListener('resize', handleResize)
  }, [width])

  const handleMobileMenuClick = () => {
    setIsMobileNavClicked(!isMobileNavClicked)
  }

  return (
    <div>
      <nav className="w-full bg-[#009000] px-10">
        <div className="w-full px-4 py-2 flex justify-between items-center">
          {/* Mobile Menu section (visible on small screens) */}

          <div
            className={`md:hidden flex items-center mr-2 ${
              isMobile ? '' : 'hidden'
            }`}>
            {/* Mobile Menu Button (visible on small screens) */}
            <button
              className="text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white"
              onClick={handleMobileMenuClick}>
              <svg
                className="w-6 h-6 text-gray-500"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path d="m4 6h16M4 12h16M4 18h16" />
              </svg>
            </button>
            {isMobile && isMobileNavClicked && (
              <NavigationTabs isMobile={isMobile} />
            )}
          </div>
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <ImageFetcher />
          </Link>

          {!isMobile && <NavigationTabs isMobile={isMobile} />}
          <Dropdown />
        </div>
      </nav>
    </div>
  )
}

export default Navbar
