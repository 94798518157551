import React, { useEffect } from 'react'
import Permissions from './Permissions'
// import Filter from './Filter'

const Edit = () => {
  return (
    <div className="w-full mx-8 ">
      {/* <Filter /> */}
      <div id="CheckBoxPermission" className="m-0">
        <Permissions />
      </div>
    </div>
  )
}

export default Edit
