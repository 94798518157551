import React, { useContext, useMemo, useState } from 'react'
import RichTextEditor from '../../../components/RichTextEditor'
import 'react-datepicker/dist/react-datepicker.css'
import userData from 'src/hooks/useData/useData'
import { transformDate } from 'src/services/timestamp'
import { ActivityProps } from '../program.interface'
import { ToastContext } from 'src/ToastProvider'
interface IVideoData {
  activity_url: string
  activity_description: string
  activity_name: string
}

export default function AddVideoForm({
  handleSubmit,
  activityData,
  deleteActivityId,
  disabled,
}: ActivityProps) {
  const { user } = userData()
  const { showToast } = useContext(ToastContext)
  const initialActivityData = {
    activity_url: '',
    activity_description: '',
    activity_name: '',
  }
  const [formData, setFormData] = useState<IVideoData>(
    activityData?.data && Object.keys(activityData.data).length === 0
      ? initialActivityData
      : (activityData.data as IVideoData)
  )
  const today = new Date()
  const formattedToday = transformDate(today, 'start')

  const saveForm = () => {
    const payload = { ...activityData, data: formData }

    handleSubmit(payload)
  }
  const permission = JSON.parse(localStorage.getItem('user')!).permission
  const disableSaveButton = useMemo(() => {
    const isDisabled =
      disabled ||
      formData.activity_name === '' ||
      formData.activity_description === '' ||
      formData.activity_description === '<p><p/>' ||
      formData.activity_description === '<p><br></p>' ||
      formData.activity_url === '' ||
      !permission.includes('edit_program')
    return isDisabled
  }, [formData])

  const resetForm = () => {
    if (Object.keys(activityData.data).length === 0) {
      setFormData(initialActivityData)
    } else {
      setFormData(activityData.data as IVideoData)
    }
  }

  const handleChange = (event: any) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  return (
    <div className="my-2">
      <div className="mb-4 flex flex-col mx-20 border-2 border-black rounded-lg p-2">
        <div className="p-2 w-full">
          <div className="mb-4 flex justify-start">
            <label
              htmlFor="activity_name"
              className="block text-gray-700 font-bold mb-2 w-1/3">
              Video Name<span className="text-red-500">*</span>
            </label>
            <input
              disabled={disabled}
              type="text"
              id="activity_name"
              name="activity_name"
              value={formData.activity_name}
              onChange={handleChange}
              className="bg-gray-300 shadow appearance-none rounded w-full mr-24 py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            />
          </div>
          <div className="mb-4 flex justify-start">
            <label
              htmlFor="activity_url"
              className="block text-gray-700 font-bold mb-2 w-1/3">
              Video Url<span className="text-red-500">*</span>
            </label>
            <input
              disabled={disabled}
              type="text"
              id="activity_url"
              name="activity_url"
              value={formData.activity_url}
              onChange={handleChange}
              className="bg-gray-300 shadow appearance-none rounded w-full mr-24 py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            />
          </div>

          <div className="mb-4 flex flex-col">
            <label
              htmlFor="activity_description"
              className="block text-gray-700 font-bold mb-2">
              Activity Description<span className="text-red-500">*</span>
            </label>
            <div className="bg-white">
              <RichTextEditor
                readOnly={disabled}
                data={formData.activity_description}
                handleChanges={(value: string) => {
                  handleChange({
                    target: {
                      name: 'activity_description',
                      value,
                    },
                  })
                }}
              />
            </div>
          </div>

          <div className="flex justify-end text-lg">
            <button
              disabled={disableSaveButton}
              onClick={resetForm}
              className={`text-white rounded-md px-8 py-2 mr-2 ${
                disableSaveButton
                  ? 'bg-sfl-disabled-grey opacity-25'
                  : 'bg-blue-800'
              }`}>
              Reset
            </button>
            {user.role == 'super_admin' &&
              !disabled &&
              activityData &&
              activityData._id !== undefined && (
                <button
                  onClick={() => {
                    deleteActivityId(activityData?._id ?? '')
                  }}
                  disabled={disabled}
                  className="bg-red-800 text-white rounded-md px-8 py-2 mr-2">
                  Delete
                </button>
              )}
            <button
              disabled={disableSaveButton}
              onClick={saveForm}
              className={`text-white py-2 px-4 rounded-lg  ${
                disableSaveButton
                  ? 'bg-sfl-disabled-grey opacity-25'
                  : 'bg-green-700 hover:bg-green-800'
              }`}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
