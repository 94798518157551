import React, { useState, useEffect } from 'react'
import { PermissionItem } from './utils/Constant'

interface PermissionsCardProps {
  title: string
  items: PermissionItem[]
  onSelectedItemsChange: any
}

const PermissionsCard = ({
  title,
  items,
  onSelectedItemsChange,
}: PermissionsCardProps) => {
  const storedItems = localStorage.getItem('selectedItems')

  const [selectedItems, setSelectedItems] = useState<{
    [key: string]: string[]
  }>(storedItems ? JSON.parse(storedItems) : {})
  useEffect(() => {
    onSelectedItemsChange(selectedItems)
  }, [selectedItems])
  localStorage.setItem('SelectPermission', JSON.stringify(selectedItems))
  const role = localStorage.getItem('formData')

  const handleChecked = (item: string, title: string) => {
    // Copy the current state into a new object
    const updatedSelectedItems = { ...selectedItems }

    // Check if the title key exists in the updatedSelectedItems object
    if (updatedSelectedItems.hasOwnProperty(title)) {
      const isSelected = updatedSelectedItems[title].includes(item)

      // Toggle the item's selection status
      if (isSelected) {
        updatedSelectedItems[title] = updatedSelectedItems[title].filter(
          selectedItem => selectedItem !== item
        )
      } else {
        updatedSelectedItems[title] = [...updatedSelectedItems[title], item]
      }
    } else {
      // If the title key doesn't exist, create it with the selected item
      updatedSelectedItems[title] = [item]
    }

    // Set the updated state with the modified object
    setSelectedItems(updatedSelectedItems)
  }

  useEffect(() => {
    if (role === 'admin') {
      localStorage.setItem('selectedItems', JSON.stringify(selectedItems))
    } else {
      localStorage.removeItem('selectedItems')
    }
  }, [selectedItems, role])

  return (
    <div className="w-[20rem] max-w-full border-2 border-gray-300 rounded-lg bg-white shadow-lg m-4 p-4">
      <div className="p-3 border-b-2 border-gray-200 text-center text-2xl font-semibold text-gray-700">
        {title}
      </div>
      <div className="mt-4 space-y-2">
        {items.map(item => (
          <div key={item.value} className="flex items-center">
            <input
              type="checkbox"
              id={item.value}
              className="form-checkbox h-5 w-5 text-blue-600"
              onChange={() => handleChecked(item.value, title)}
            />
            <label htmlFor={item.value} className="ml-3 text-lg text-gray-800">
              {item.label}
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PermissionsCard
