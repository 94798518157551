import React, { useEffect, useState } from 'react'
import { getISOEndDate, getISOStartDate } from '../../services/timestamp'
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { programSchema } from './program.interface'
import userData from 'src/hooks/useData/useData'
import { fetchProgramData } from '../../services'
import CustomSidebar from '../Settings/CustomSideBar'

const today = new Date()
const formattedToday = getISOStartDate(today)

const initialProgramData: programSchema = {
  status: 0,
  program_name: '',
  program_short_description: '',
  program_description: '',
  program_start_date: formattedToday,
  program_end_date: getISOEndDate(today),
  modules_list: [],
}

const Program = () => {
  const navigate = useNavigate()
  const { user } = userData()
  const { programId } = useParams()
  const [isLoading, setIsLoading] = useState(programId ? true : false)
  const [programData, setProgramData] =
    useState<programSchema>(initialProgramData)

  const updateData = async () => {
    setIsLoading(true)
    try {
      const data = await fetchProgramData(programId)
      if (data.ok === false) {
        navigate(-1)
      } else {
        setProgramData(data)
      }
    } catch (error) {
      console.error('Error fetching program data:', error)
      navigate(-1)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (programId) {
      updateData()
    } else {
      setProgramData(initialProgramData)
    }
  }, [programId])

  return (
    <div className="flex">
      <div className="">
        <ChildNav />
      </div>
      <div className="w-full bg-white rounded-md shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] p-4">
        <Outlet context={{ programData, setProgramData }} />
      </div>
    </div>
  )
}

const getUserPermissions = () => {
  const user = JSON.parse(localStorage.getItem('user')!)
  return user && user.permission ? user.permission : []
}

export default Program

const ChildNav: React.FC = () => {
  const { programId } = useParams<{ programId: string }>()
  const location = useLocation()
  const navigate = useNavigate()
  const permissions = getUserPermissions()
  const childNavData = [
    { path: `/program/${programId}/details`, linkText: 'Program Details' },
  ]
  if (permissions.includes('assign_learners')) {
    childNavData.push({
      path: `/program/${programId}/assign`,
      linkText: 'Assign',
    })
  }

  return (
    <div className="flex flex-col gap-5 p-5">
      <div>
        <button
          onClick={() => navigate('/dashboard')}
          className="bg-[#202e59] hover:bg-[#2c5282] text-white rounded-md px-8 py-2 mr-2">
          Back
        </button>
      </div>
      <div className="px-2">
        <CustomSidebar />
      </div>
      {childNavData && (
        <div className="flex p-2">
          <ul className="flex flex-col text-center text-black bg-white rounded-md w-[14rem]">
            {childNavData.map(navItem => (
              <li
                className={`m-1 px-2 rounded-lg p-2 ${
                  location.pathname.startsWith(navItem.path)
                    ? 'bg-[#202e59] text-white'
                    : ''
                }`}
                key={navItem.path}>
                <Link to={navItem.path}>{navItem.linkText}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
