import React, {
  useMemo,
  useState,
  HTMLAttributes,
  HTMLProps,
  useReducer,
  useEffect,
} from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { API_URL } from '../../../services/index'
import axios from 'axios'
import { toast } from 'react-toastify'

const range = (len: number) => {
  const arr: number[] = []
  for (let i = 0; i < len; i++) {
    arr.push(i)
  }
  return arr
}

import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  RowData,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
  Table,
} from '@tanstack/react-table'

export interface Organization {
  admin_name?: string
  address: string
  city: string
  country: string
  created_by: string
  email_id: string
  email_template: [{}]
  linkedin_url: string
  mobile_no: string
  organization_logo: string
  organization_name: string
  social_profile: [string]
  state: string
  status: number
  super_admin: string
  valid_from: string
  valid_to: string
  _id: string
  isuser?: boolean
}
interface IRenderTableProps {
  data: Organization[]
  isuser?: boolean
}
declare module '@tanstack/react-table' {
  interface ColumnMeta<TData extends RowData, TValue> {
    filterVariant?: 'text' | 'range' | 'select' | 'select-organization'
  }
}

const RenderTable = ({ data, isuser }: IRenderTableProps) => {
  const [organization, setOrganization] = useState<Organization[]>([])
  let { programId } = useParams()
  let navigate = useNavigate()
  const [rowSelection, setRowSelection] = React.useState({})
  const [globalFilter, setGlobalFilter] = React.useState('')
  useEffect(() => {
    console.log(data)
    let enrolled: any = []

    setEnrolled(enrolled)
    console.log(rowSelection)
  }, [data])

  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  )

  const handleDelete = async (id: string) => {
    try {
      await axios.delete(`${API_URL}/sub_admin/delete/${id}`)
      const updatedAdmins = organization.filter(admin => admin._id !== id)
      setOrganization(updatedAdmins)
      alert('Admin deleted successfully')
    } catch (error) {
      console.error('Error deleting admin:', error)
      alert('Failed to delete admin')
    }
  }
  const columns = React.useMemo<ColumnDef<Organization, any>[]>(
    () => [
      {
        accessorFn: row => `${new Date(row.valid_from).toDateString()}`,
        id: 'Created_On',
        header: () => (
          <li className="px-10 font-medium list-none">Created On</li>
        ),
        cell: info => info.getValue(),
      },
      {
        accessorFn: row => {
          if (Object.keys(row).includes('organization_name')) {
            return `${row.organization_name}`
          } else {
            return `${row.admin_name}`
          }
        },
        id: 'organization_name',
        header: () => (
          <li className="px-10 font-medium list-none">Organization_name</li>
        ),
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'country',
        header: () => <li className="px-10 font-medium list-none">Country</li>,
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'state',
        header: () => <li className="px-10 font-medium list-none">State</li>,
        cell: info => info.getValue(),
      },
      {
        accessorKey: 'status',
        header: () => <li className="px-10 font-medium list-none">Status</li>,
        cell: info => (info.getValue() == 1 ? 'Active' : 'Inactive'),
      },
      {
        accessorKey: '_id',
        header: () => <li className="px-10 font-medium list-none">Actions</li>,
        cell: info => {
          const id = info.getValue() // Get the ID from the cell value
          return (
            <div className="flex gap-2 items-center justify-center">
              <button
                onClick={() => {
                  if (!isuser) {
                    navigate(`/settings/manageBU/editBU?org_id=${id}`)
                  } else {
                    navigate(`/settings/manageUsers/editAdmin?_id=${id}`)
                  }
                }}
                className="bg-[#202e59] p-2 w-[4rem] rounded-md text-white font-medium hover:bg-[#2c5282]">
                Edit
              </button>
              <button
                onClick={() => handleDelete(id)} // Use `id` here
                className="bg-[#970c0c] font-medium text-white p-2 rounded-md">
                Delete
              </button>
            </div>
          )
        },
      },
    ],
    [data]
  )
  const [Enrolled, setEnrolled] = React.useState([])

  const table = useReactTable({
    data,
    columns,
    filterFns: {},
    state: {
      columnFilters,
      rowSelection,
    },
    enableRowSelection: true,
    onRowSelectionChange: value => {
      setRowSelection(value)
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: false,
    enableMultiRowSelection: true,
  })
  const handleAssign = async (path: string) => {
    const assignData = await fetch(`${API_URL}/${path}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      body: JSON.stringify({
        learnerArray: data
          .filter((value: any, index: number) => {
            let keys = Object.keys(rowSelection)
            if (keys.includes(index.toString())) {
              return value._id
            }
          })
          .map((value: any) => value._id),
        program_id: programId,
      }),
    })
      .then(async response => {
        if (!response.ok) {
          return
        }
        const result = await response.json()

        setRowSelection({})
      })
      .catch(error => console.log(error))
  }
  return (
    <div className="p-10 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]  bg-white rounded-md  flex flex-col overflow-x-auto">
      <b>No of Items Selected : {Object.keys(rowSelection).length}</b>
      <div className="overflow-x-auto w-[90%]">
        <table className=" bg-gray-300 border-solid	 border-2	 border-gray-300">
          <thead className="border-solid border-2 border-gray-300">
            {table.getHeaderGroups().map((headerGroup, i) => (
              <tr
                key={headerGroup.id}
                className="bg-gray-500 rounded-md py-2 text-lg mb">
                {headerGroup.headers.map(header => {
                  return (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder ? null : (
                        <>
                          <div
                            className={
                              header.column.getCanSort()
                                ? 'cursor-pointer select-none'
                                : ''
                            }
                            onClick={header.column.getToggleSortingHandler()}>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </div>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} />
                            </div>
                          ) : null}
                        </>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody className="border-solid border-2 bg-white border-gray-100">
            {table.getRowModel().rows.map((row, i) => {
              return (
                <tr
                  className={`h-10 text-center ${
                    i % 2 === 0 ? 'bg-gray-200' : 'bg-white'
                  }`}
                  key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td
                        key={cell.id}
                        className="p-0 border-solid	 border-2	 border-gray-300">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <div className="h-2" />
      <div className="flex items-center gap-2">
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(0)}
          disabled={!table.getCanPreviousPage()}>
          {'<<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}>
          {'<'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}>
          {'>'}
        </button>
        <button
          className="border rounded p-1"
          onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          disabled={!table.getCanNextPage()}>
          {'>>'}
        </button>
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-1">
          | Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={e => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0
              table.setPageIndex(page)
            }}
            className="border p-1 rounded w-16"
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}>
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>

      <div>
        {/* <button onClick={() => refreshData()}>Force Rerender</button> */}
      </div>
      <div>
        {/* <button onClick={() => refreshData()}>Refresh Data</button> */}
      </div>
    </div>
  )
}
function Filter({ column }: { column: Column<any, unknown> }) {
  const columnFilterValue: any = column.getFilterValue()
  const { filterVariant } = column.columnDef.meta ?? {}

  return filterVariant === 'range' ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[0] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [value, old?.[1]])
          }
          placeholder={`Min`}
          className="w-24 border shadow rounded"
        />
        <DebouncedInput
          type="number"
          value={(columnFilterValue as [number, number])?.[1] ?? ''}
          onChange={value =>
            column.setFilterValue((old: [number, number]) => [old?.[0], value])
          }
          placeholder={`Max`}
          className="w-24 border shadow rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : filterVariant === 'select' ? (
    <select
      onChange={e => column.setFilterValue(e.target.value)}
      value={columnFilterValue?.toString()}>
      <option value="">All</option>
      <option value="complicated">complicated</option>
      <option value="relationship">relationship</option>
      <option value="single">single</option>
    </select>
  ) : (
    <DebouncedInput
      className="w-36 border shadow rounded-md"
      onChange={value => column.setFilterValue(value)}
      placeholder={`Search...`}
      type="text"
      value={(columnFilterValue ?? '') as string}
    />

    // See faceted column filters example for datalist search suggestions
  )
}

// A typical debounced input react component
function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  ...props
}: {
  value: string | number
  onChange: (value: string | number) => void
  debounce?: number
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>) {
  const [value, setValue] = React.useState(initialValue)

  React.useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value)
    }, debounce)

    return () => clearTimeout(timeout)
  }, [value])

  return (
    <input
      {...props}
      value={value}
      onChange={e => setValue(e.target.value)}
      style={{ width: '180px' }}
    />
  )
}
function IndeterminateCheckbox({
  indeterminate,
  className = '',
  ...rest
}: { indeterminate?: boolean } & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!)

  React.useEffect(() => {
    if (typeof indeterminate === 'boolean') {
      ref.current.indeterminate = !rest.checked && indeterminate
    }
  }, [ref, indeterminate])

  return (
    <input
      type="checkbox"
      ref={ref}
      className={className + ' cursor-pointer'}
      {...rest}
    />
  )
}

export default RenderTable
