import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { API_URL } from '../../services'
import RenderTable, { Organization } from './Tablesection/TableDisplay'
import { toast } from 'react-toastify'
interface UserData {
  admin_name: string
  address: string
  city: string
  company_logo: string
  company_name: string
  country: string
  created_by: string
  email_id: string
  linkedin_url: string
  mobile_no: string
  permission: string[] // Assuming permission is an array of strings
  profile_pic: string
  role: string
  social_profile: any[] // You can define a specific type if needed
  state: string
  status: number
  username: string
  valid_from: string
  valid_to: string
  _id: string
  // Add other properties if applicable
}

const ManageBU = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([])
  const [userData, setUserData] = useState<UserData | null>(null) // Initialize userData as null

  useEffect(() => {
    const userDataString = localStorage.getItem('user')
    if (userDataString) {
      setUserData(JSON.parse(userDataString))
    }
  }, [])

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('token')
      const response = await axios(`${API_URL}/organization/FetchAll`, {
        method: 'POST', // Use POST method
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
      })
      const data = response.data
      if (data && data.data.length !== 0) {
        setOrganizations(data.data)
      } else {
        setOrganizations([])
      }
    } catch (error) {
      toast.error('Error fetching organizations')
      console.error(error)
    }
  }

  useEffect(() => {
    if (userData && userData.role === 'super_admin') {
      fetchData()
    }
  }, [userData]) // Fetch data only when userData changes and userData.role is 'super_admin'

  // Check if the user is a super admin or userData is null
  if (!userData || userData.role !== 'super_admin') {
    return (
      <div className="flex items-center justify-center">
        You do not have permission to access this section.
      </div>
    )
  }

  return (
    <div>
      <div className="flex justify-between">
        {userData.permission.includes('add_bu') && (
          <div className="w-32 text-center text-lg my-3 right-0 text-white bg-green-700 px-2 py-3 rounded-md">
            <Link to="/settings/manageBU/addBU">
              <button>Add BU</button>
            </Link>
          </div>
        )}
      </div>
      <RenderTable data={organizations}></RenderTable>
    </div>
  )
}

export default ManageBU
