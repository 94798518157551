import React, { useState, useMemo } from 'react'
import { FaDownload, FaTrash } from 'react-icons/fa'
import * as XLSX from 'xlsx'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import { API_URL } from 'src/services'
import { OutletProps } from './Learner_list'
import { useOutletContext } from 'react-router-dom'
import moment from 'moment'
import Toaster from 'src/components/Toaster'
import LearnerFilter from './Filter'
import { LearnerResponse, OrganizationOption } from 'src/interfaces/learner'

const ManageLearners = () => {
  const { organizationsList }: OutletProps = useOutletContext()
  const [showFilter, setShowFilter] = useState(true)

  const user = JSON.parse(localStorage.getItem('user') || '{}')

  // Check if the user has the 'access' permission
  const userPermissions = user?.permission || []
  const accessButton = userPermissions.includes('Manage_access')
  const reportButton = userPermissions.includes('download_reports')

  const [showInactivePopup, setShowInactivePopup] = useState(false)
  const [showValidityPopup, setShowValidityPopup] = useState(false)
  const [showAccessPopup, setShowAccessPopup] = useState(false)
  const [showDeletePopup, setShowDeletePopup] = useState(false)
  const [selectedId, setSelectedId] = useState<any>({})
  const [collectedData, setcollectedData] = useState<LearnerResponse[]>([])
  const [isExtendValidity, setExtendedValidity] = useState(false)
  const [DateSelect, setDateSelect] = useState<any>({})
  const [isactive, setActive] = useState()
  const [toasterEnable, setToasterenable] = useState(false)

  const toggleFilter = () => {
    setShowFilter(!showFilter)
  }

  const handleInactiveClick = () => {
    setShowInactivePopup(true)
  }

  const handleValidityClick = () => {
    setShowValidityPopup(true)
  }

  const handleAccessClick = () => {
    setShowAccessPopup(true)
  }

  const handleDeleteClick = () => {
    setShowDeletePopup(true)
  }

  const handleCancelInactive = () => {
    setShowInactivePopup(false)
  }

  const handleOkInactive = () => {
    setShowInactivePopup(false)
  }

  const handleCancelValidity = () => {
    setShowValidityPopup(false)
  }

  const handleOkValidity = () => {
    setShowValidityPopup(false)
  }

  const handleCancelAccess = () => {
    setShowAccessPopup(false)
  }

  const handleOkAccess = () => {
    setShowAccessPopup(false)
  }
  const handleCancelDelete = () => {
    setShowDeletePopup(false)
  }

  const handleOkDelete = async () => {
    await deleteSelectedLearners()
    setShowDeletePopup(false)
    refreshTableData()
  }

  const parentSubmit = () => {}
  const { selectedValues, selectedLearnerOrg } = useMemo(() => {
    let selectedLearnerOrg: OrganizationOption = {
      _id: '',
      organization_name: '',
      organization_id: '',
      valid_from: new Date(),
      valid_to: new Date(),
    }
    // enter if atleast one item is selected in table
    if (Object.keys(selectedId).length !== 0) {
      const selectedItems = collectedData.filter(
        (item: any, index: number) => selectedId[index]
      )

      // get organizations of all learners selected
      const queue = new Set(selectedItems.map(value => value.organization_id))

      // enable extent validity button only if all selected learners are from one Organization
      if (queue.size === 1) {
        setExtendedValidity(true)
        const [first] = queue

        // use selected organization start date and end date to show in calendar,
        // learner validity can be extended only till valdity of their respective organization validity
        const orgDate = organizationsList.find(value => value._id === first)
        if (orgDate) {
          selectedLearnerOrg = { ...orgDate }
        }
      } else {
        setExtendedValidity(false)
        setToasterenable(false)
      }
      return { selectedValues: selectedItems, selectedLearnerOrg }
    } else {
      return { selectedValues: [], selectedLearnerOrg }
    }
  }, [selectedId])

  const convertJsonToFile = async () => {
    var ws = XLSX.utils.json_to_sheet(selectedValues)
    var wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'learners')
    XLSX.writeFile(wb, 'learnerlist.xlsx')
  }

  let extendValidity = async () => {
    const response = await axios(`${API_URL}/extend_learner`, {
      method: 'POST', // Use POST method
      data: {
        validity_from: selectedValues.map(value => value.valid_from)[0],
        validity_to: DateSelect,
        learnerid: selectedValues.map(value => value._id),
        organization_id: selectedValues.map(value => value.organization_id)[0],
        status: isactive,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
    setExtendedValidity(false)
  }
  async function deleteSelectedLearners(): Promise<void> {
    const response = await fetch(`${API_URL}/bulk_delete`, {
      body: JSON.stringify(selectedValues),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
    })
  }

  const refreshTableData = () => {
    parentSubmit()
  }

  return (
    <div>
      <div className="flex justify-between my-8 mx-8">
        {toasterEnable && (
          <Toaster
            message="Learners updated!"
            type="success"
            onClose={() => {}}></Toaster>
        )}
        {accessButton && (
          <div className="flex items-center gap-10">
            <button
              className="bg-blue-800 text-white px-5 py-2 mx-2 rounded-lg"
              onClick={handleAccessClick}>
              Manage Access
            </button>
            {isExtendValidity && (
              <button
                className="bg-blue-800 text-white px-5 py-2 mx-2 rounded-lg"
                onClick={handleValidityClick}>
                Extend Validity
              </button>
            )}

            <button
              className="bg-red-600 text-white px-5 py-2 mx-2 rounded-lg flex items-center"
              onClick={handleDeleteClick}>
              Delete Learners
              <FaTrash className="ml-1" />
            </button>
          </div>
        )}
        {reportButton && (
          <button
            className="bg-blue-800 text-white px-5 py-2 mx-2 rounded-lg flex items-center"
            onClick={convertJsonToFile}>
            Download Report
            <FaDownload className="ml-1" />
          </button>
        )}
      </div>

      <LearnerFilter
        type="managelearners"
        manageLearners={setSelectedId}
        parentSelec={setSelectedId}
        setcollectedData={setcollectedData}
        parentSubmit={parentSubmit}
      />
      {showInactivePopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md">
            <h2 className="text-2xl font-bold mb-4 text-center">
              Confirmation
            </h2>
            <p className="mb-4">
              Are you sure to <b>Inactivate</b> the selected users?
            </p>
            <div className="flex justify-end font-semibold text-lg">
              <button
                className=" px-5 py-2 rounded-md mr-2"
                onClick={handleCancelInactive}>
                Cancel
              </button>
              <button
                className="bg-green-800 text-white px-5 py-2 rounded-md"
                onClick={handleOkInactive}>
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {showValidityPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md">
            <h2 className="text-2xl font-bold mb-4 text-center">
              Confirmation
            </h2>
            <p className="mb-4 font-medium">
              New Valid Upto Date:
              <DatePicker
                selected={moment(DateSelect).toDate()}
                value={DateSelect}
                onChange={date => setDateSelect(date)}
                className="disabled:cursor-not-allowed shadow appearance-none border border-2 rounded-xl outline-nonew-full py-2 px-3 text-gray-700 mr-64"
                minDate={moment(selectedLearnerOrg.valid_from).toDate()}
                maxDate={moment(selectedLearnerOrg.valid_to).toDate()}
              />
            </p>
            <p className="mb-4 font-medium">
              Learners New Status:
              <select
                onChange={(event: any) => {
                  setActive(event.target.value)
                }}
                className="border border-gray-400 px-3 py-1 rounded-md ml-2 bg-gray-300 w-40">
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
              </select>
            </p>
            <div className="flex justify-end font-semibold text-lg">
              <button
                className=" px-5 py-2 rounded-md mr-2"
                onClick={handleCancelValidity}>
                Cancel
              </button>
              <button
                className="bg-green-800 text-white px-5 py-2 rounded-md"
                onClick={extendValidity}>
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {showAccessPopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md">
            <h2 className="text-2xl font-bold mb-4 text-center">
              Confirmation
            </h2>
            <h2 className="font-medium text-lg mb-4">
              Choose the Access You Want to Enable for Selected Learners
            </h2>
            <div className="grid grid-cols-2 gap-4">
              <div className="bg-gray-300 p-2">
                <input
                  type="checkbox"
                  className="mr-2 w-4 h-4"
                  id="viewProgram"
                />
                <label htmlFor="viewProgram">View Program</label>
              </div>
              <div className="bg-gray-300 p-2">
                <input
                  type="checkbox"
                  className="mr-2 w-4 h-4"
                  id="downloadReports"
                />
                <label htmlFor="downloadReports">Download Reports</label>
              </div>
              <div className="bg-gray-300 p-2">
                <input
                  type="checkbox"
                  className="mr-2 w-4 h-4"
                  id="viewReports"
                />
                <label htmlFor="viewReports">View Reports</label>
              </div>
              <div className="bg-gray-300 p-2">
                <input
                  type="checkbox"
                  className="mr-2 w-4 h-4"
                  id="viewSkillProfile"
                />
                <label htmlFor="viewSkillProfile">View Skill Profile</label>
              </div>
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="px-5 py-2 rounded-md mr-2"
                onClick={handleCancelAccess}>
                Cancel
              </button>
              <button
                className="bg-green-800 text-white px-5 py-2 rounded-md"
                onClick={handleOkAccess}>
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
      {showDeletePopup && (
        <div className="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-md">
            <h2 className="text-2xl font-bold mb-4 text-center">
              Delete Confirmation
            </h2>
            <p className="mb-4">
              Are you sure you want to delete the selected learners?
            </p>
            <div className="flex justify-end font-semibold text-lg">
              <button
                className=" px-5 py-2 rounded-md mr-2"
                onClick={handleCancelDelete}>
                Cancel
              </button>
              <button
                className="bg-red-600 text-white px-5 py-2 rounded-md"
                onClick={handleOkDelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ManageLearners
