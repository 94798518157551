import React, { useState, useEffect } from 'react'
import { EventProvider } from './pages/Functionality/context/eventcontext'
import AppRoutes from './AppRoutes'
import { BrowserRouter } from 'react-router-dom'
import { ToastProvider } from './ToastProvider'
import './styles/index.css'
import { ToastContainer } from 'react-toastify'
const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  useEffect(() => {
    const token = localStorage.getItem('token')
    const user = localStorage.getItem('user')

    if (token && user) {
      setIsLoggedIn(true)
    }
  }, [])

  return (
    <ToastProvider>
      <EventProvider>
        <BrowserRouter>
          <ToastContainer />
          <AppRoutes />
        </BrowserRouter>
      </EventProvider>
    </ToastProvider>
  )
}

export default App
