import React from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export interface IToasterMessage {
  message: string
  type?: 'success' | 'error' | 'info' | 'warning'
}
export interface IToasterMessageProps extends IToasterMessage {
  onClose: () => void
}

export default function Toaster({
  message,
  type,
  onClose,
}: IToasterMessageProps) {
  if (type === 'success') {
    toast.success(message, {
      onClose: () => {
        void onClose()
      },
    })
  } else if (type === 'error') {
    toast.error(message, {
      onClose: () => {
        void onClose()
      },
    })
  } else if (type === 'info') {
    toast.info(message, {
      onClose: () => {
        void onClose()
      },
    })
  } else if (type === 'warning') {
    toast.warn(message, {
      onClose: () => {
        void onClose()
      },
    })
  } else {
    toast(message, {
      onClose: () => {
        void onClose()
      },
    })
  }

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      closeOnClick
      pauseOnFocusLoss={false}
      pauseOnHover={false}
    />
  )
}
