import React, { useState, useEffect } from 'react'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import CustomSideBar from './CustomSideBar'
import AppBreadcrumbs from '../Functionality/AppBreadcrumbs'

const Settings = () => {
  return (
    <div className="flex overflow-hidden">
      <SideNav />
      <div className="bg-white p-10 rounded-md shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]">
        <Outlet />
      </div>
    </div>
  )
}

export default Settings

const SideNav = () => {
  const [activePath, setActivePath] = useState('')
  const [userRole, setUserRole] = useState<string>('') // State to store user role
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const userItem = localStorage.getItem('user')
    const user = userItem ? JSON.parse(userItem) : {} // Ensure userItem is not null
    setUserRole(user.role || '')
  }, [])

  useEffect(() => {
    setActivePath(location.pathname)
  }, [location])

  const childNavData = [
    { path: '/settings/profile', linkText: 'Profile' },
    ...(userRole === 'super_admin'
      ? [
          { path: '/settings/icons', linkText: 'Add Logo' },
          { path: '/settings/manageBU', linkText: 'Manage BU' },
          { path: '/settings/manageUsers', linkText: 'Manage Users' },
        ]
      : []),
  ]

  return (
    <div className="flex flex-col gap-5 p-5">
      <div>
        <button
          onClick={() => {
            navigate(-1)
          }}
          className="bg-[#202e59] hover:bg-[#2c5282] text-white rounded-md px-8 py-2 mr-2">
          Back
        </button>
      </div>
      <div className="w-[18rem]">
        <CustomSideBar />
      </div>
      {childNavData && (
        <div className="flex p-2">
          <ul className="flex flex-col text-center text-black bg-white rounded-md w-[14rem]">
            {childNavData.map(navItem => (
              <li
                className={`m-1 px-2 rounded-lg p-2 ${
                  location.pathname.startsWith(navItem.path)
                    ? 'bg-[#202e59] text-white'
                    : ''
                }`}
                key={navItem.path}>
                <Link to={navItem.path}>{navItem.linkText}</Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
