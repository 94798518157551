import React, { ReactNode, createContext, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'

const ToastContext = createContext({
  showToast: (message: string, type?: string) => {},
})

const ToastProvider = ({ children }: { children?: ReactNode }) => {
  const showToast = (message: string, type?: string) => {
    if (type === 'success') {
      toast.success(message)
    } else if (type === 'error') {
      toast.error(message)
    } else if (type === 'info') {
      toast.info(message)
    } else if (type === 'warning') {
      toast.warn(message)
    } else {
      toast(message)
    }
  }

  return (
    <ToastContext.Provider value={{ showToast }}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss={false}
        pauseOnHover={false}
      />
      {children}
    </ToastContext.Provider>
  )
}

export { ToastContext, ToastProvider }
