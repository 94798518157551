import React, { ChangeEvent, useMemo, useContext } from 'react'
import { useNavigate, useParams, useOutletContext } from 'react-router-dom'
import { deleteProgram, insertPrograms } from '../../services'
import {
  getISOStartDate,
  isValidEndDate,
  isValidStartDate,
} from '../../services/timestamp'
import RichTextEditor from '../../components/RichTextEditor'
import CalendarComponent from '../../components/CalendarComponent'
import Toggle from './Toggle'
import { IoAddCircleSharp } from 'react-icons/io5'
import ModuleStructure from './ModuleStructure'
import { moduleSchema, ProgramOutletProps } from './program.interface'
import { ToastContext } from 'src/ToastProvider'
import userData from 'src/hooks/useData'
import { MdSaveAlt } from 'react-icons/md'
import { RiDeleteBin6Line } from 'react-icons/ri'

const today = new Date()
const formattedToday = getISOStartDate(today)

const CreateProgram = () => {
  const { user } = userData()
  const { showToast } = useContext(ToastContext)
  const navigate = useNavigate()
  const { programId } = useParams()
  const { programData, setProgramData }: ProgramOutletProps = useOutletContext()
  const permission = JSON.parse(localStorage.getItem('user')!).permission
  console.log(programData)
  const handleChange = (
    event: ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    setProgramData({
      ...programData,
      [event.target.name]: event.target.value,
    })
  }

  const toggleStatus = () => {
    if (programData.modules_list.length == 0 && programData.status == 0) {
      showToast(
        'Status can be Live only if the Program contains atleast one module!!',
        'warning'
      )
    } else {
      setProgramData((prev: any) => {
        const { status } = prev
        return { ...prev, ['status']: status ? 0 : 1 }
      })
    }
  }

  const disableSaveButton = useMemo(() => {
    // Perform some expensive computation using the data
    const disabled =
      programData.status === 1 ||
      programData.program_name === '' ||
      programData.program_description === '' ||
      programData.program_description === '<p><p/>' ||
      programData.program_description === '<p><br></p>' ||
      programData.program_short_description === '' ||
      !permission.includes('edit_program') ||
      !permission.includes('create_program')

    return disabled
  }, [programData])

  const handleSubmit = (event: any) => {
    event.preventDefault()
    // Validation
    const errors: string[] = []
    if (
      isValidStartDate(programData.program_start_date, formattedToday) &&
      programId === undefined
    ) {
      const message = 'Start date must be after today.'
      showToast(message, 'error')
      errors.push(message)
    }

    if (
      isValidEndDate(
        formattedToday,
        programData.program_start_date,
        programData.program_end_date
      )
    ) {
      const message = 'End date must be after start date and after today.'
      showToast(message, 'error')
      errors.push(message)
    }

    if (errors.length > 0) {
      return
    }

    insertPrograms(programData)
      .then((e: any) => {
        if (e.status == 200) {
          setProgramData(e.data.program)
          showToast(e.data.message, 'success')

          navigate('/program/' + e.data.insert_id + '/details')
        } else {
          showToast(e.data.message, 'error')
        }
      })
      .catch((e: any) => {
        showToast(e.message, 'error')
      })
  }
  const handleChanges = (value: string) => {
    handleChange({
      target: {
        name: 'program_description',
        value,
      },
    } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>)
  }

  return (
    <div className="bg-white">
      <div className="  w-full">
        <div className="pt-16 w-full">
          <Toggle
            switchStatus={toggleStatus}
            toggleStatus={programData.status}
          />

          <div className=" flex flex-col gap-10">
            <div className="flex  gap-3  flex-col">
              <label
                htmlFor="program_name"
                className=" text-black   font-semibold ">
                Program Name :<span className="text-red-500">*</span>
              </label>

              <input
                type="text"
                id="program_name"
                name="program_name"
                value={programData.program_name}
                onChange={handleChange}
                disabled={programData.status === 1}
                className="bg-global-grey border-2 w-full shadow  rounded-md  p-2 text-gray-700 "
              />
            </div>

            <div className="flex gap-3 flex-col">
              <label
                htmlFor="program_short_description"
                className="text-black font-semibold mb-2 w-2/3">
                Program Short Description:
                <span className="text-red-500">*</span>
              </label>
              <textarea
                id="program_short_description"
                name="program_short_description"
                value={programData.program_short_description}
                onChange={handleChange}
                disabled={programData.status === 1}
                className="bg-global-grey border-2 shadow rounded-md h-[6rem] w-full text-gray-700 p-2"
              />
            </div>

            <div className="flex flex-col">
              <label
                htmlFor="program_description"
                className="block text-gray-700 font-bold mb-2">
                Program Description<span className="text-red-500">*</span>
              </label>
              <div className="">
                <RichTextEditor
                  readOnly={programData.status === 1}
                  data={programData.program_description}
                  handleChanges={handleChanges}
                />
              </div>
            </div>
            <div className="flex flex-col rounded-md justify-center md:flex-row gap-2">
              <div className="w-full flex flex-row items-center gap-2">
                <label
                  htmlFor="program_start_date"
                  className="block text-gray-700 font-bold">
                  Start Date:<span className="text-red-500">*</span>
                </label>
                <div className="flex rounded-md flex-col">
                  <CalendarComponent
                    disabled={
                      programData.status === 1 || programId !== undefined
                    }
                    selected={programData.program_start_date}
                    name="program_start_date"
                    handleChange={handleChange}
                    type="start"
                  />
                </div>
              </div>
              <div className="w-full flex flex-row items-center justify-end gap-2">
                <label
                  htmlFor="program_end_date"
                  className="block text-gray-700 font-bold">
                  End Date:<span className="text-red-500">*</span>
                </label>
                <div className=" flex flex-col">
                  <CalendarComponent
                    disabled={programData.status === 1}
                    selected={programData.program_end_date}
                    name="program_end_date"
                    handleChange={handleChange}
                    type="end"
                  />
                </div>
              </div>
            </div>

            <div className="flex justify-center text-lg gap-2 my-4">
              { programId && !programData.status && permission.includes('delete_program') &&(
                <button
                  onClick={() => {
                    deleteProgram({ program_id: programId }).then((e: any) => {
                      if (e.status == 200) {
                        navigate('/dashboard')
                      } else {
                        showToast('Program deletion failed', 'error')
                      }
                    })
                  }}
                  disabled={programData.status === 1}
                  className="flex flex-row items-center gap-1 bg-[#9b2c2c] cursor-pointer hover:bg-[#d74c4c] text-white rounded-md px-8 py-2 mr-2">
                  Delete <RiDeleteBin6Line />
                </button>
              )}
              <button
                disabled={disableSaveButton}
                onClick={handleSubmit}
                type="submit"
                className={` flex items-center gap-1 text-white py-2 px-4 rounded-lg  ${
                  disableSaveButton
                    ? 'bg-sfl-disabled-grey opacity-25'
                    : 'bg-[#009000] hover:bg-[#276749]'
                }`}>
                {programId ? 'Save Program' : 'Create Program'} <MdSaveAlt />
              </button>
            </div>
          </div>

          <hr className="h-px my-8  border-0 dark:bg-gray-700" />
        </div>
        {programId && (
          <div className="flex justify-center">
            <button
              disabled={
                programData.status === 1 ||
                permission.includes('create_program') == false
              }
              onClick={() => {
                navigate('/program/' + programId + '/createModule')
              }}
              className={`text-white p-2 px-4 my-4 text-xl rounded-lg flex ${
                disableSaveButton
                  ? 'bg-sfl-disabled-grey opacity-25'
                  : 'bg-[#009000] hover:bg-[#276749] '
              }`}>
              Add Modules
              <IoAddCircleSharp className="ml-1 mt-1 w-6 h-6 align-center" />
            </button>
          </div>
        )}
      </div>
      {programId && (
        <>
          <hr className="h-px my-8  border-0 " />
          <div className="flex flex-wrap gap-3 p-2 ">
            {programData &&
              programData.modules_list &&
              programData.modules_list.map((module: moduleSchema) => {
                return (
                  <ModuleStructure
                    moduleData={module}
                    programId={programId}
                    key={module._id}
                  />
                )
              })}
          </div>
        </>
      )}
    </div>
  )
}

export default CreateProgram
