import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import ChildNav from '../../components/childNav'
import { OrganizationOption } from 'src/interfaces/learner'
import { API_URL } from 'src/services/index'


const getUserPermissions = () => {
  const user = JSON.parse(localStorage.getItem('user')!)
  return user && Array.isArray(user.permission) ? user.permission : []
}

const permissions = getUserPermissions()

type NavItem = {
  path: string
  linkText: string
}

const childNavData: NavItem[] = []

if (permissions.includes('enroll_learners')) {
  childNavData.push({
    path: '/learners/learners-list',
    linkText: 'Enroll Learners',
  })
}

if (permissions.includes('manage_learners')) {
  childNavData.push({
    path: '/learners/manageLearners',
    linkText: 'Manage Learners',
  })
}



const Learners = () => {
  const [organizationsList, setOrganizations] = useState<OrganizationOption[]>(
    []
  )

  // Fetch Org Dropdown data
  useEffect(() => {
    if (organizationsList.length === 0) {
      fetchOrganizations()
    }
  }, [])

  const fetchOrganizations = async () => {
    console.log('fetchOrganizations')
    try {
      const response = await fetch(`${API_URL}/getorganization`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      })
      const data = await response.json()
      console.log(data)
      if (data && data.organizations.length !== 0) {
        setOrganizations(data.organizations)
      } else {
        setOrganizations([])
      }
    } catch (error) {
      console.error('Error fetching organizationsList:', error)
      setOrganizations([])
    }
  }
  return (
    <div className="flex">
      <ChildNav childNavData={childNavData} />
      <div className="w-full">
        <Outlet context={{ organizationsList }} />
      </div>
    </div>
  )
}

export default Learners
