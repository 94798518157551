import React from 'react'
import { Navigate, RouteProps, Route, Outlet } from 'react-router-dom'

interface ProtectedRouteProps {
  requires: string
}

const ProtectedRoute = () => {
  const token = localStorage.getItem('token')!
  return token ? <Outlet /> : <Navigate to="/login" />
}
export const AccessProtected = ({ requires }: any) => {
  const rights = JSON.parse(localStorage.getItem('user')!).permission
  if (!rights || !localStorage.getItem('user')) {
    return <Navigate to="/login" />
  }
  return requires.find((value: any) => rights.includes(value)) ? (
    <Outlet />
  ) : (
    <Navigate to="/dashboard" />
  )
}

export default ProtectedRoute
