export interface PermissionItem {
  label: string
  value: string
}

export interface PermissionData {
  title: string
  items: PermissionItem[]
}
export const PERMISSION_DATA: PermissionData[] = [
  {
    title: 'Program Management',
    items: [
      { label: 'Create Program', value: 'create_program' },
      { label: 'View Program', value: 'view_program' },
      { label: 'Edit Program', value: 'edit_program' },
      { label: 'Delete Program', value: 'delete_program' },
      { label: 'Learners Assign/Unassign Page', value: 'assign_learners' },

      {
        label: 'Assign/Unassign Access',
        value: 'button_access',
      },
    ],
  },

  {
    title: 'Assessment Settings',
    items: [
      { label: 'Assessment', value: 'assessment' },
      { label: 'Add Assessment', value: 'add_assessment' },
      { label: 'Upload Reports', value: 'upload_reports' },
      { label: 'Edit Assessment', value: 'edit_assessment' },
    ],
  },

  {
    title: 'Learner Management',
    items: [
      { label: 'Learners', value: 'learners' },
      { label: 'Enroll Learners', value: 'enroll_learners' },
      { label: 'Manage Learners', value: 'manage_learners' }, // Default checked
      {
        label: 'Extend Validity , Manage Access , Delete Learners',
        value: 'Manage_access',
      },
      {
        label: 'Download Report',
        value: 'download_reports',
      },
    ],
  },

  {
    title: 'Analytics Management',
    items: [
      {
        label: 'Analytics Page',
        value: 'view_analytics',
      },
      {
        label: 'View Progress Analytics',
        value: 'view_progress_analytics',
      },
      {
        label: 'View Department Level Analytics',
        value: 'view_department_level_analytics',
      },
      {
        label: 'View Assessment Level Analytics',
        value: 'view_assessment_level_analytics',
      },
      {
        label: 'View Learner Level Analytics',
        value: 'view_learner_level_analytics',
      },
      {
        label: 'Download Reports',
        value: 'download_reports',
      },
    ],
  },
  {
    title: 'Global Settings Management',
    items: [
      {
        label: 'View Profile',
        value: 'View_profile',
      },
    ],
  },
]
