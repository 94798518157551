import React, { useState, useEffect } from 'react'
import PermissionsCard from './PermissionsCard'
import { PERMISSION_DATA } from './utils/Constant'
import { API_URL } from '../../services/index'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

interface SelectedItemsState {
  [key: string]: Array<string>
}
const Permissions = () => {
  const navigate = useNavigate()
  const [selectedItems, setSelectedItems] = useState<SelectedItemsState>({})

  const handleSelectedItemsChange = (items: any, title: string) => {
    const keys = Object.keys(items)
    if (keys.length) {
      const value = items[keys[0]]
      setSelectedItems(prevSelectedItems => ({
        ...prevSelectedItems,
        [keys[0]]: [...value],
      }))
    }
  }
  const urlSearchParams = window.location.href.split('?')[1] // Get the search parameters string
  const searchParamsArray = urlSearchParams.split('&') // Split into key-value pairs

  const searchData: any = {} // Initialize an object to store the search data

  searchParamsArray.forEach(param => {
    const [key, value] = param.split('=') // Split each pair into key and value
    searchData[key] = decodeURIComponent(value) // Decode URI components and store in the object
  })

  const BackendCall = async (arr: any) => {
    try {
      const response = await axios({
        method: 'POST',
        url: `${API_URL}/sub_admin/permission`,
        data: { _id: searchData?._id, permission: arr }, // FormData goes here
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      const data = response.status
      if (data == 200) {
        navigate('/settings/manageUsers')
      }
    } catch (error) {
      toast.error('Error fetching permission')
      console.error('Error fetching permission', error)
    }
  }
  const onSubmitFunction = () => {
    let arr: any = []
    for (const key in selectedItems) {
      const valueArray = selectedItems[key]
      valueArray.forEach(element => arr.push(element))
    }
    const form = document.getElementById('CheckBoxPermission')
    if (form) {
      const checkboxes = form.querySelectorAll('input[type="checkbox"]')
      const checkedValues: any = []

      checkboxes.forEach(checkbox => {
        // Check if checkbox is an HTMLInputElement to access 'checked' and 'value'
        if (checkbox instanceof HTMLInputElement && checkbox.checked) {
          checkedValues.push(checkbox.id)
        }
      })

      if (checkedValues.length > 0) BackendCall(checkedValues)
    } else {
      toast.error('Form element not found.')
      console.log('Form element not found.')
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Get the token from localStorage
        const token = localStorage.getItem('token')

        // Make an authorized POST request using Axios
        const response = await axios(`${API_URL}/sub_admin/permission`, {
          method: 'POST', // Use POST method
          data: { _id: searchData?._id },
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        })
        const data = response.data
        if (data && data.data) {
          let r = data.data
          r.forEach((element: any) => {
            document.getElementById(element)?.setAttribute('checked', 'true')
          })
        } else {
          toast.error('Error fetching permission')
          console.log('not found')
        }
      } catch (error) {
        toast.error('Error fetching permission')
        console.error('Error fetching organizations:', error)
      }
    }
    if (searchData && searchData._id && searchData._id != null) fetchData()
  }, [searchData])
  return (
    <div className="w-full ">
      <div className="m-4 ">
        <h1 className="text-4xl text-gray-700 font-medium text-center">
          Access and Rights Management for Admins
        </h1>
      </div>
      <div className="flex permission-list">
        <div className="permission-group">
          {PERMISSION_DATA.slice(0, 3).map((permission, index) => (
            <PermissionsCard
              key={index}
              title={permission.title}
              items={permission.items}
              onSelectedItemsChange={handleSelectedItemsChange}
            />
          ))}
        </div>
        <div className="permission-group">
          {PERMISSION_DATA.slice(2).map((permission, index) => (
            <PermissionsCard
              key={index}
              title={permission.title}
              items={permission.items}
              onSelectedItemsChange={handleSelectedItemsChange}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-end mr-56 mb-8">
        <button
          className="bg-green-700 text-white px-4 py-2 m-2 rounded-md"
          onClick={onSubmitFunction}>
          Submit
        </button>
      </div>
    </div>
  )
}

export default Permissions
