import React, { useEffect } from 'react'
import { useState } from 'react'

type props = {
  switchStatus: () => void
  toggleStatus: number
}

const Toggle = ({ switchStatus, toggleStatus }: props) => {
  const [isLive, setIsLive] = useState(toggleStatus)

  useEffect(() => {
    setIsLive(toggleStatus)
  }, [toggleStatus])

  return (
    <div className="flex justify-end">
      <span className="mr-2 text-sm font-medium text-gray-900">Draft</span>
      <label className="w-12 items-center cursor-pointer">
        <input
          type="checkbox"
          value={isLive}
          className="sr-only peer"
          onChange={switchStatus}
          checked={isLive === 1}
        />
        <div className="relative w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
      </label>
      <span className="ml-2 ms-3 text-sm font-medium text-gray-900">Live</span>
    </div>
  )
}

export default Toggle
