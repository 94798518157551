import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import RichTextEditor from '../../components/RichTextEditor/index'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { insertModules, deleteModule } from '../../services'
import userData from 'src/hooks/useData'
import ActivityList from './ActivityCollection'

import {
  ProgramOutletProps,
  moduleSchema,
  programSchema,
} from './program.interface'
import {
  getISOStartDate,
  isValidEndDate,
  isValidStartDate,
} from 'src/services/timestamp'
import { ToastContext } from 'src/ToastProvider'
import CalendarComponent from 'src/components/CalendarComponent'

const today = new Date()
const formattedToday = getISOStartDate(today)
const CreateModule = () => {
  const { user } = userData()
  const { showToast } = useContext(ToastContext)
  const navigate = useNavigate()
  const { programId, moduleId } = useParams()
  const { programData, setProgramData }: ProgramOutletProps = useOutletContext()

  let initialModuleData: moduleSchema = {
    program_id: programId ?? '',
    module_name: '',
    module_description: '',
    module_start_date: new Date(programData.program_start_date).toISOString(),
    module_end_date: new Date(programData.program_end_date).toISOString(),
    activity_list: [],
  }
  const permission = JSON.parse(localStorage.getItem('user')!).permission
  const [moduleData, setModuleData] = useState<moduleSchema>(initialModuleData)

  const updateProgramData = (newProgram: programSchema) => {
    setProgramData(newProgram)
  }
  const updateModuleData = (updatedModule: moduleSchema) => {
    setProgramData(prevProgramData => {
      return {
        ...prevProgramData,
        modules_list: prevProgramData.modules_list.map(module => {
          if (module._id === moduleId) {
            return { ...updatedModule }
          }
          return { ...module }
        }),
      }
    })
  }

  useEffect(() => {
    if (moduleId !== undefined) {
      if (programData.modules_list.length !== 0) {
        let module = programData.modules_list.find(
          pro => pro._id && pro._id.toString() === moduleId
        )
        if (module !== undefined) {
          setModuleData({ ...module, program_id: programId ?? '' })
        } else {
          setModuleData(initialModuleData)
        }
      }
    } else {
      setModuleData(initialModuleData)
    }
  }, [moduleId, programData])

  const disableSaveButton = useMemo(() => {
    const disabled =
      programData.status === 1 ||
      moduleData.module_name === '' ||
      moduleData.module_description === '' ||
      moduleData.module_description === '<p><p/>' ||
      moduleData.module_description === '<p><br></p>' ||
      !permission.includes('edit_program')
    return disabled
  }, [moduleData, programData.status])

  const handleChange = (event: any) => {
    setModuleData({
      ...moduleData,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = (event: any) => {
    event.preventDefault()
    // Validation
    const errors: string[] = []
    if (
      isValidStartDate(moduleData.module_start_date, formattedToday) &&
      moduleId === undefined
    ) {
      const message = 'Start date must be after today.'
      showToast(message, 'error')
      errors.push(message)
    }

    if (
      isValidEndDate(
        formattedToday,
        moduleData.module_start_date,
        moduleData.module_end_date
      )
    ) {
      const message = 'End date must be after start date and after today.'
      showToast(message, 'error')
      errors.push(message)
    }

    if (errors.length > 0) {
      return
    }
    insertModules(moduleData).then((e: any) => {
      if (e.status == 200) {
        updateProgramData(e.data.program)
        navigate('/program/' + e.data.insert_id + '/details')
        showToast('Module saved successfully', 'success')
      } else {
        showToast('Module save failed', 'error')
      }
    })
  }

  const handleChanges = (value: string) => {
    handleChange({
      target: {
        name: 'module_description',
        value,
      },
    } as ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)
  }

  return (
    <div className="mb-4 flex flex-col w-full">
      <div className=" flex flex-col p-2 w-full gap-4">
        <div className="flex justify-start flex-col">
          <label
            htmlFor="module_name"
            className="block text-gray-700 font-bold mb-2 mr-4">
            Module Name:<span className="text-red-500">*</span>
          </label>
          <div className="w-full">
            <input
              type="text"
              id="module_name"
              name="module_name"
              value={moduleData.module_name}
              onChange={handleChange}
              disabled={programData.status === 1}
              className="bg-white shadow appearance-none rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none"
            />
          </div>
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="module_description"
            className="block text-gray-700 font-bold mb-2">
            Module Description<span className="text-red-500">*</span>
          </label>
          <div className="bg-white">
            <RichTextEditor
              readOnly={programData.status === 1}
              data={moduleData.module_description}
              handleChanges={handleChanges}
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-2">
          <div className="w-full flex flex-row items-center gap-2">
            <label
              htmlFor="module_start_date"
              className="block text-gray-700 font-bold">
              Start Date:<span className="text-red-500">*</span>
            </label>
            <div className="flex flex-col">
              <CalendarComponent
                disabled={programData.status === 1 || moduleId !== undefined}
                selected={moduleData.module_start_date}
                name="module_start_date"
                minDate={programData.program_start_date}
                maxDate={programData.program_end_date}
                handleChange={handleChange}
                type="start"
              />
            </div>
          </div>
          <div className="w-full flex flex-row items-center justify-end gap-2">
            <label
              htmlFor="module_end_date"
              className="block text-gray-700 font-bold">
              End Date:<span className="text-red-500">*</span>
            </label>
            <div className="flex flex-col">
              <CalendarComponent
                disabled={programData.status === 1}
                selected={moduleData.module_end_date}
                name="module_end_date"
                minDate={programData.program_start_date}
                maxDate={programData.program_end_date}
                handleChange={handleChange}
                type="end"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end text-lg gap-2 my-4">
          <button
            onClick={() => {
              navigate('/program/' + programId + '/details')
            }}
            className="bg-blue-800 text-white rounded-md px-8 py-2 mr-2">
            Back
          </button>
          {user.role == 'super_admin' && !programData.status && moduleId && (
            <button
              onClick={() => {
                if (confirm('Are you sure you want to delete this module ?'))
                  deleteModule({
                    program_id: programId,
                    module_id: moduleId,
                  }).then((e: any) => {
                    if (e.status == 200) {
                      showToast(e.data.message, 'success')
                      updateProgramData(e.data.program)
                      navigate('/program/' + programId + '/details')
                    } else {
                      showToast('Module deletion failed', 'error')
                    }
                  })
              }}
              className="bg-red-800 text-white rounded-md px-8 py-2 mr-2"
              disabled={programData.status === 1}>
              Delete
            </button>
          )}
          <button
            disabled={disableSaveButton}
            onClick={handleSubmit}
            type="submit"
            className={`text-white py-2 px-4 rounded-lg  ${
              disableSaveButton
                ? 'bg-sfl-disabled-grey opacity-25'
                : 'bg-green-700 hover:bg-green-800'
            }`}>
            {moduleId ? 'Save Module' : 'Create Module'}
          </button>
        </div>
      </div>
      {programId && moduleId && (
        <ActivityList
          moduleData={moduleData}
          disabled={
            programData.status === 1 || !permission.includes('edit_program')
          }
          updateProgramData={updateProgramData}
          updateModuleData={updateModuleData}
        />
      )}
    </div>
  )
}

export default CreateModule
