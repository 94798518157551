import React, { useContext, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useParams } from 'react-router-dom'
import { CreateActivityProps, activitySchema } from './program.interface'
import { IoAddCircleSharp } from 'react-icons/io5'
import { ToastContext } from '../../ToastProvider'
import { uniqueId } from '../../services/utils'
const CreateActivity = ({
  moduleData,
  disabled,
  updateModuleData,
}: CreateActivityProps) => {
  const { showToast } = useContext(ToastContext)
  let basicTypes = ['video', 'assessment']
  const [activeStep, setActiveStep] = useState<number>(0)
  const permission = JSON.parse(localStorage.getItem('user')!).permission
  const setType = (type: string) => {
    const activityData = moduleData.activity_list.some(
      activity => activity._id && activity._id?.indexOf('temp-') >= 0
    )
    if (moduleData && !activityData) {
      const newActivityData: activitySchema = {
        ...{ _id: uniqueId(`temp-`) },
        type,
        activity_start_date: moduleData.module_start_date,
        activity_end_date: moduleData.module_end_date,
        status: 0,
        data: {},
      }
      const activityList = [{ ...newActivityData }, ...moduleData.activity_list]
      updateModuleData({ ...moduleData, activity_list: activityList })

      setActiveStep(0)
    } else {
      showToast('Complete open form')
    }
  }
  return (
    <>
      <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
      <div className="bg-indigo-400 flex gap-4 justify-center m-4 mb-8 p-4 rounded-lg">
        {activeStep === 0 && (
          <button
            disabled={disabled || !permission.includes('edit_program')}
            onClick={() => setActiveStep(1)}
            className={`text-xl text-white px-5 py-2 rounded-md flex ${
              disabled ? 'bg-sfl-disabled-grey opacity-25' : 'bg-green-800'
            }`}>
            Add Events
            <IoAddCircleSharp className="mt-1 ml-1 w-6 h-6 align-center" />
          </button>
        )}
        {activeStep === 1 && (
          <>
            {basicTypes.map(type => {
              return (
                <button
                  key={uniqueId(`${type}-`)}
                  onClick={() => {
                    setType(type)
                  }}
                  className="bg-pink-1000 border-2 h-24 p-4 rounded-lg w-1/4">
                  <h2>Add {type}</h2>
                </button>
              )
            })}
          </>
        )}
      </div>
    </>
  )
}

export default CreateActivity
